import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  input,
  Input,
  model,
  OnInit,
  Output,
  signal,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PageSectionTableHeaderFiltersComponent } from 'gain-lib/page/page-section/page-section-table-header/page-section-table-header-filters/page-section-table-header-filters.component';

export interface TableBatchAction {
  icon: string;
  tooltip: string;
  pending: boolean;
  action: () => void;
}

@Component({
  selector: 'gax-page-section-table-header',
  templateUrl: './page-section-table-header.component.html',
  styleUrl: './page-section-table-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageSectionTableHeaderComponent implements OnInit {
  private _search$ = new Subject<string | null>();

  public readonly filterCmp = contentChild(
    PageSectionTableHeaderFiltersComponent,
  );

  protected readonly searchInputElement =
    viewChild<ElementRef<HTMLInputElement>>('searchBar');

  constructor() {
    this._search$
      .pipe(
        map((query) => {
          if (query != null) {
            // trim beginning/trailing whitespace
            query = query.trim();
            // emit null values if there is an empty string so
            // listeners can more easily identify "empty" search queries
            // without having to  empty strings
            return query === '' ? null : query;
          }
          return query;
        }),
        distinctUntilChanged(),
        debounceTime(this.searchChangeDebounce ?? 0),
        takeUntilDestroyed(),
      )
      .subscribe((search) => {
        this.searchQuery.set(search);
        this.searchChange.emit(search);
      });
  }
  @Input()
  @HostBinding('class.removeHeaderBorder')
  removeHeaderBorder: boolean = false;

  @Input()
  searchChangeDebounce: number | null = 300;

  @Output()
  searchChange: EventEmitter<string | null> = new EventEmitter();

  isFocused!: boolean;

  @HostListener('focus', ['$event']) onFocus() {
    this.isFocused = true;
  }

  @HostListener('blur', ['$event']) onBlur() {
    this.isFocused = false;
  }

  @HostBinding('class.reverse-headers') @Input() reverseHeaders: boolean =
    false;

  @Input()
  searchbar: boolean = false;

  protected onSearchChange(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this._search$.next(searchValue);
  }

  public clearSearch() {
    this._search$.next(null);
    const searchEl = this.searchInputElement();
    if (searchEl != null) {
      searchEl.nativeElement.value = '';
    }
  }

  searchQuery = signal<string | null>(null);

  showFilter = input<boolean | null>(null);

  showFilterToggle = computed(
    () => this.showFilter() === true || this.filterCmp() !== undefined,
  );

  @Output()
  filterToggle = new EventEmitter<boolean>();
  filterActive = model<boolean>(false);

  toggleFilter(showFilters?: boolean) {
    this.filterActive.update((active) => showFilters ?? !active);
    this.filterToggle.emit(this.filterActive());
  }

  @Input()
  showActions: boolean = false;

  @Input()
  actions: TableBatchAction[] = [];

  ngOnInit() {
    if (this.showFilter() === true) {
      const cmpName = 'gax-page-section-table-header';
      if (this.filterCmp() == null) {
        console.warn(
          `When filters are enabled for '${cmpName}', 'gax-page-section-table-header-filters' component should be used to render filters.`,
        );
      }
    }
  }
}
