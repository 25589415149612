@if (filtersApplied()) {
  <div class="applied-filters">
    <div class="applied-filters-content">
      <mat-chip-listbox>
        @for (chip of filterChipsDisplay(); track chip.value) {
          <mat-chip [removable]="true" (removed)="chip.remove()">
            <span class="mat-chip-filter-label">
              {{ chip.label }}
            </span>
            {{ chip.displayValue }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        }
      </mat-chip-listbox>
    </div>
    <div class="applied-filters-actions">
      <button mat-button (click)="clearFilters()">Clear All</button>
    </div>
  </div>
} @else {
  <div class="filters">
    <div class="filters-content">
      <ng-content></ng-content>
    </div>
    <div class="filters-actions">
      <button mat-button color="secondary" (click)="resetFilters()">
        Reset
      </button>
      <button mat-button color="primary" (click)="applyFilters()">Apply</button>
    </div>
  </div>
}
