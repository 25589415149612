import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Host,
  input,
  model,
  Optional,
  Output,
} from '@angular/core';
import { PageSectionTableHeaderComponent } from 'gain-lib/page/page-section/page-section-table-header/page-section-table-header.component';
import { GaFilterChip } from 'gain-lib/forms/src/filter-chip';

import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gax-page-section-table-header-filters',
  templateUrl: './page-section-table-header-filters.component.html',
  styleUrl: './page-section-table-header-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionTableHeaderFiltersComponent {
  @Output()
  public readonly apply = new EventEmitter<void>();
  @Output()
  public readonly filterReset = new EventEmitter<void>();

  public readonly appliedFilterChips = input.required<GaFilterChip[]>();

  public readonly filterChipsDisplay = computed(() => {
    let chips = this.appliedFilterChips();

    const searchQuery = this.tableHeader.searchQuery();
    if (searchQuery != null) {
      chips = [
        {
          label: 'Search Query',
          displayValue: `"${searchQuery}"`,
          value: searchQuery,
          remove: () => {
            this.tableHeader.clearSearch();
          },
        } satisfies GaFilterChip,
        ...chips,
      ];
    }

    return chips;
  });
  public filtersApplied = model<boolean>(false);

  protected tableHeader: PageSectionTableHeaderComponent;

  constructor(
    @Host() @Optional() tableHeader?: PageSectionTableHeaderComponent,
  ) {
    if (tableHeader == null) {
      throw new Error(
        'gax-page-section-table-header-filters must be a child of gax-page-section-table-header',
      );
    }
    this.tableHeader = tableHeader;

    toObservable(this.filterChipsDisplay).subscribe(() => {
      this.hideFiltersIfNoneApplied();
    });
  }

  protected hideFiltersIfNoneApplied() {
    if (this.filterChipsDisplay().length === 0 && this.filtersApplied()) {
      this.tableHeader.toggleFilter(false);
      this.clearFilters();
    }
  }

  protected applyFilters() {
    this.filtersApplied.set(true);
    this.apply.emit();
    this.hideFiltersIfNoneApplied();
  }

  public clearFilters() {
    this.filtersApplied.set(false);
    this.resetFilters();
  }

  protected resetFilters() {
    this.filterReset.emit();
    this.tableHeader.clearSearch();
  }
}
